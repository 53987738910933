import styles from './Skills.module.css';
import checkmarkIcon from '../../assets/checkmark-dark.svg'
import Skill from '../../common/Skill';

function Skills() {
  return (
    <section id='skills' className={styles.container}>
      <h1 className='sectionTitle'>Leadership Skills</h1>
        <div className={styles.skillList}>
            <Skill src={checkmarkIcon} skill='Project management'/>
            <Skill src={checkmarkIcon} skill='Strategic planning'/>
            <Skill src={checkmarkIcon} skill='Team building'/>
            <Skill src={checkmarkIcon} skill='Cross-functional team collaboration'/>
        </div>
      <hr />
      <div className={styles.skillList}>
            <Skill src={checkmarkIcon} skill='People development'/>
            <Skill src={checkmarkIcon} skill='Risk mitigation'/>
            <Skill src={checkmarkIcon} skill='Retention and recruiting'/>
            <Skill src={checkmarkIcon} skill='Trust building'/>
        </div>
      <hr />
        <h1 className='sectionTitle'>Techinal Skills</h1>
        <div className={styles.skillList}>
            <Skill src={checkmarkIcon} skill='HTML'/>
            <Skill src={checkmarkIcon} skill='CSS'/>
            <Skill src={checkmarkIcon} skill='JavaScript'/>
            <Skill src={checkmarkIcon} skill='TypeScript'/>
            <Skill src={checkmarkIcon} skill='Node'/>
            <Skill src={checkmarkIcon} skill='React'/>
            <Skill src={checkmarkIcon} skill='Flutter'/>
            <Skill src={checkmarkIcon} skill='Angular'/>
        </div>
        <hr />
        <div className={styles.skillList}>
            <Skill src={checkmarkIcon} skill='SQL'/>
            <Skill src={checkmarkIcon} skill='C#'/>
            <Skill src={checkmarkIcon} skill='Java'/>
            <Skill src={checkmarkIcon} skill='Python'/>
            <Skill src={checkmarkIcon} skill='Firebase'/>
        </div>
        <hr />
        <div className={styles.skillList}>
            <Skill src={checkmarkIcon} skill='Xamarin'/>
            <Skill src={checkmarkIcon} skill='Xcode'/>
            <Skill src={checkmarkIcon} skill='Android Studio'/>
            <Skill src={checkmarkIcon} skill='Visual Studio'/>
            <Skill src={checkmarkIcon} skill='SQL Server Management Studio'/>
        </div>
        <hr />
        <div className={styles.skillList}>
            <Skill src={checkmarkIcon} skill='Git'/>
            <Skill src={checkmarkIcon} skill='Azure Devops'/>
            <Skill src={checkmarkIcon} skill='Azure Pipelines'/>
        </div>
    </section>
  )
}

export default Skills
