import React from 'react'
import styles from './Project.module.css';
import pioneer from '../../assets/pioneer-dark.png'
import corteva from '../../assets/corteva.png';
import pentTest from '../../assets/penTest.png';
import theHunterJournal from '../../assets/thj.png';
import taxidermy from '../../assets/taxidermy.png';
import bot from '../../assets/bot.png';
import scrape from '../../assets/scrape.png';
import ProjectCard from '../../common/ProjectCard';

function Project() {
  return (
    <section id='projects' className={styles.container}>
      <h1 className="sectionTitle">
        Projects
      </h1>
      <div className={styles.projectsContainer}>
        <ProjectCard 
                      link='https://www.corteva.com/products-and-services/seeds.html'
                      src={corteva}
                      alt='Corteva logo'
                      header='Falcon'
                      paragraph='Invoicing Mobile and Web App'
                      sub='C# | React | SQL'/>
        <ProjectCard 
                      link='https://www.corteva.com/products-and-services/seeds.html'
                      src={pioneer}
                      alt='Pioneer logo'
                      header='Connex'
                      paragraph='Responsive Invoicing Web App'
                      sub='C# | Angular | SQL'/>
        <ProjectCard 
                      link='https://apps.apple.com/us/app/the-hunter-journal/id6461267347'
                      src={theHunterJournal}
                      alt='The Hunter Journal logo'
                      header='The Hunter Journal'
                      paragraph='Hybrid Mobile app'
                      sub='Flutter | Firebase'/>
        <ProjectCard link='https://www.taxidermysoftware.com/'
                      src={taxidermy}
                      alt='Taxidermy Logo'
                      header='Taxidermy Toolbox'
                      paragraph='Taxidermy Web App'
                      sub='Angular | Firebase'/>
        <ProjectCard 
                      src={pentTest}
                      alt='Hacker Logo'
                      header='Cyber Consultancy'
                      paragraph='Pen Testing of Web app/APIs'
                      sub='Exposed vulnerable APIs'/>
        <ProjectCard link='https://github.com/mprin/twitter-bot'
                      src={bot}
                      alt='Twitter bot Logo'
                      header='Twitter Bot'
                      paragraph='Twitter bot using ChatGPT'
                      sub='Python'/>
        <ProjectCard link='https://github.com/mprin/news-scraper'
                      src={scrape}
                      alt='Website Scraper Logo'
                      header='Website Scraper'
                      paragraph='Scrape a website and store info'
                      sub='Python | Firebase'/>
      </div>
    </section>
  )
}

export default Project

