import React from 'react'

import styles from './Hero.module.css';
import heroImg from '../../assets/max.png';
import sun from '../../assets/sun.svg';
import moon from '../../assets/moon.svg';
import githubLight from '../../assets/github-light.svg';
import linkedInLight from '../../assets/linkedin-light.svg';
import githubDark from '../../assets/github-dark.svg';
import linkedInDark from '../../assets/linkedin-dark.svg';
import CV from '../../assets/cv.pdf';
import { useTheme } from '../../common/ThemeContext';

function Hero() {
    const { theme, toggleTheme } = useTheme();
    const themeIcon = theme === 'light' ? sun : moon;
    const githubIcon = theme === 'light' ? githubLight : githubDark;
    const linkedInIcon = theme === 'light' ? linkedInLight : linkedInDark;
  return (
    <>
        <section id='hero' className={styles.container}>
            <div className={styles.colorModeContainer}>
                <img className={styles.hero} 
                     src={heroImg} 
                     alt='Profile picture of Max Pringle' 
                />
                <img className={styles.colorMode}
                     src={themeIcon}
                     alt='Color mode icon'
                     onClick={toggleTheme}
                />
            </div>
            <div className={styles.info}>
                <h1>
                    Max
                    <br />
                    Pringle
                </h1>
                <h2>
                    People Leader & 
                    <br />
                    Software Guy
                </h2>
                <span >
                    <a href='https://github.com/mprin' target='_blank'>
                        <img src={githubIcon}
                             alt='Github icon'
                        />
                    </a>
                    <a href='https://www.linkedin.com/in/pringlemax/' target='_blank'>
                        <img src={linkedInIcon}
                             alt='LinkedIn icon'
                        />
                    </a>
                </span>

                    <p className={styles.description}>With a decade of software engineering and six years of people management</p>
                    <a href={CV} download>
                        <button className='hover'>RESUME</button>
                    </a>
            </div>
        </section>
    </>
  )
}

export default Hero
