import React from 'react'

function ProjectCard({link, src, header, alt, paragraph, sub}) {
  return (
    <a href={link} target='_blank' className='card'>
          <img className='hover' src={src} alt={alt} />
          <h3>{header}</h3>
          <p className='card-paragraph'>{paragraph}</p>
          <p className='card-paragraph'>{sub}</p>
        </a>
  )
}

export default ProjectCard
