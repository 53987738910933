import styles from './Leadership.module.css';
import React from 'react'
import Card from '../../common/Card';

function Leadership() {
  return (
    <section id='leadership' className={styles.container}>
      <h1 className="sectionTitle">
        Leadership Philosophy
      </h1>
      <div className={styles.leadershipContainer}>
        <Card 
        title='Leadership is Influence'
        paragraph="It doesn't take a title or position to provide leadership, but rather courage to influence the team in the right direction."/>
        <Card 
        title='Be the change you want to see'
        paragraph="Don't complain, just be the person you know you should be and lead the charge by influencing others to also do what is right"/>
        <Card 
        title='Never stop learning'
        paragraph="There should never be a time that you stop improving yourself. Always learn, always read, always experiment."/>
      </div>
    </section>
  )
}

export default Leadership
