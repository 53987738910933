import React from 'react';
import './App.css';
import Hero from './components/hero/Hero';
import Project from './components/project/Project';
import Skills from './components/skills/Skills';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import Leadership from './components/leadership/Leadership';


function App() {
  return (
    <>
      <Hero />
      <Leadership />
      <Project/>
      <Skills/>
      <Contact />
      <Footer />
    </>
  );
}

export default App;
