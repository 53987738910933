import React from 'react'

function Card({src, title, paragraph}) {
  return (
    <>
    <div className='main-card'>
      <img className='card-image' src={src}></img>
      <h2 className='card-title'>{title}</h2>
      <p className='card-paragraph'>{paragraph}</p>
    </div>
    </>
  )
}

export default Card
